<template>
    <div>
        <div class="page-wrapper">
        <div class="topbar-one">
            <div class="container">
                <div class="topbar-one__left">
                    <a href="#">zhyldyzacademy@protonmail.com</a>
                    <a href="#">+996 555 94 95 01</a>
                </div><!-- /.topbar-one__left -->
                <div class="topbar-one__right">
                    <a href="#">Login</a>
                    <a href="#">Register</a>
                </div><!-- /.topbar-one__right -->
            </div><!-- /.container -->
        </div><!-- /.topbar-one -->
        <header class="site-header site-header__header-one site-header__inner-page ">
            <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky">
                <div class="container clearfix">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="logo-box clearfix">
                        <a class="navbar-brand" href="index.html">
                            <img :src="require('../../assets/logo_1.png')" class="main-logo" width="128" alt="Awesome Image" />
                        </a>
                        <div class="header__social">
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="https://www.facebook.com/zhyldyz.academy" target="_blank"><i class="fab fa-facebook-square"></i></a>
                            <a href="https://api.whatsapp.com/send/?phone=%2B996704949501&text&app_absent=0" target="_blank"><i class="fab fa-whatsapp"></i></a>
                            <a href="https://www.instagram.com/zhyldyz_academy/" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div><!-- /.header__social -->
                        <button class="menu-toggler" data-target=".main-navigation">
                            <span class="kipso-icon-menu"></span>
                        </button>
                    </div><!-- /.logo-box -->
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="main-navigation">
                        <ul class=" navigation-box">
                            <li >
                                <a @click="goTo('Home')" style="font-weight: 700;">Home</a>
                               <!--<ul class="sub-menu">
                                    <li><a href="index.html">Home 01</a></li>
                                    <li><a href="index-2.html">Home 02</a></li>
                                    <li><a href="index-3.html">Home 03</a></li>
                                    <li><a href="#">Header Versions</a>
                                        <ul class="sub-menu">
                                            <li><a href="index.html">Header 01</a></li>
                                            <li><a href="index-2.html">Header 02</a></li>
                                            <li><a href="index-3.html">Header 03</a></li>
                                        </ul>
                                    </li>
                                </ul> /.sub-menu -->
                            </li>
                            <!--<li>
                                <a href="#">Pages</a>
                                <ul class="sub-menu">
                                    <li><a href="about.html">About Page</a></li>
                                    <li><a href="gallery.html">Gallery</a></li>
                                    <li><a href="pricing.html">Pricing Plans</a></li>
                                    <li><a href="faq.html">FAQ'S</a></li>
                                </ul> 
                            </li>/.sub-menu -->
                            <li>
                                <a @click="goTo('Courses')" style="font-weight: 700;">Courses</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="courses.html">Courses</a></li>
                                    <li><a href="course-details.html">Course Details</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li >
                                <a @click="goTo('Teachers')" style="font-weight: 700;">Teachers</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="teachers.html">Teachers</a></li>
                                    <li><a href="team-details.html">Teachers Details</a></li>
                                    <li><a href="become-teacher.html">Become Teacher</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li >
                                <a @click="goTo('News')" style="font-weight: 700;">News</a>
                                <!-- <ul class="sub-menu">
                                    <li><a href="news.html">News Page</a></li>
                                    <li><a href="news-details.html">News Details</a></li>
                                </ul>/.sub-menu -->
                            </li>
                            <li class="current">
                                <a @click="goTo('About')" style="font-weight: 700;">About</a>
                            </li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                    <div class="right-side-box">
                        <a class="header__search-btn search-popup__toggler" href="#"><i class="kipso-icon-magnifying-glass"></i>
                            <!-- /.kipso-icon-magnifying-glass --></a>
                    </div><!-- /.right-side-box -->
                </div>
                <!-- /.container -->
            </nav>
            <div class="site-header__decor">
                <div class="site-header__decor-row">
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-1"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-2"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-3"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                </div><!-- /.site-header__decor-row -->
            </div><!-- /.site-header__decor -->
        </header><!-- /.site-header -->
        <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li class="active"><a href="#">About</a></li>
                </ul><!-- /.list-unstyled -->
                <h2 class="inner-banner__title">About</h2><!-- /.inner-banner__title -->
            </div><!-- /.container -->
        </section><!-- /.inner-banner -->
        <section class="about-one about-one__about-page">
            <img :src="require('../../assets/images/circle-stripe.png')" class="about-one__circle" alt="">
            <div class="container text-center">
                <div class="block-title text-center">
                    <h2 class="block-title__title">Let’s do study with <br>
                        expert teachers</h2><!-- /.block-title__title -->
                </div><!-- /.block-title -->
                <div class="about-one__img">
                    <div class="row">
                        <div class="col-lg-6">
                            <img :src="require('../../assets/images/about-2-1.jpeg')" alt="">
                        </div><!-- /.col-lg-6 -->
                        <div class="col-lg-6">
                            <img :src="require('../../assets/images/about-2-2.jpeg')" alt="">
                        </div><!-- /.col-lg-6 -->
                    </div><!-- /.row -->
                    <div class="about-one__review">
                        <p class="about-one__review-count counter">88750</p><!-- /.about-one__review-count -->
                        <div class="about-one__review-stars">
                            <i class="fas fa-star"></i><!-- /.fa fa-star -->
                            <i class="fas fa-star"></i><!-- /.fa fa-star -->
                            <i class="fas fa-star"></i><!-- /.fa fa-star -->
                            <i class="fas fa-star"></i><!-- /.fa fa-star -->
                            <i class="fas fa-star"></i><!-- /.fa fa-star -->
                        </div><!-- /.about-one__stars -->
                        <p class="about-one__review-text">students loved us</p><!-- /.about-one__review-text -->
                    </div><!-- /.about-one__review -->
                </div><!-- /.about-one__img -->
                <p class="about-one__text">There are many variations of passages of lorem ipsum available, but the majority have
                    <br>
                    suffered alteration in some form, by injected humour words which don't look even slightly <br> believable.
                    Lorem
                    Ipsn gravida nibh vel velit auctor aliquetn auci elit cons.</p><!-- /.about-one__text -->
                <a href="#" class="thm-btn about-one__btn">Start Learning Now</a><!-- /.thm-btn -->
            </div><!-- /.container -->
        </section><!-- /.about-one about-one__about-page -->
        <section class="team-one  ">
            <div class="container">
                <div class="block-title text-center">
                    <h2 class="block-title__title">Meet the best <br>
                        teachers</h2><!-- /.block-title__title -->
                </div><!-- /.block-title -->
                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-1.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Adelaide Hunter</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-2.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Christina Newman</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-3.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Gilbert Daniels</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-4.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Austin Caldwell</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.team-one team-page -->
        <section class="video-one">
            <div class="container">
                <img :src="require('../../assets/images/scratch-1-1.png')" class="video-one__scratch" alt="">
                <div class="row">
                    <div class="col-lg-6 d-flex align-items-end">
                        <div class="video-one__content">
                            <h2 class="video-one__title">Take a tour dolor <br>
                                sit amet, consect <br>
                                etur elit</h2><!-- /.video-one__title -->
                            <a href="#" class="thm-btn video-one__btn">Learn More</a><!-- /.thm-btn -->
                        </div><!-- /.video-one__content -->
                    </div><!-- /.col-lg-6 -->
                    <div class="col-lg-6">
                        <div class="video-one__img">
                            <img :src="require('../../assets/images/video-1-1.jpeg')" alt="">
                            <a href="#" class="video-one__popup"><i class="fas fa-play"></i><!-- /.fas fa-play --></a>
                        </div><!-- /.video-one__img -->
                    </div><!-- /.col-lg-6 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.video-one -->
        <section class="brand-one  ">
            <div class="container">
                <div class="brand-one__carousel owl-carousel owl-theme">
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                </div><!-- /.brand-one__carousel owl-carousel owl-theme -->
            </div><!-- /.container -->
        </section><!-- /.brand-one -->
        <section class="testimonials-one  ">
            <div class="container">
                <div class="block-title text-center">
                    <h2 class="block-title__title">What our students <br>
                        have to say</h2><!-- /.block-title__title -->
                </div><!-- /.block-title -->
                <div class="testimonials-one__carousel owl-carousel owl-theme">
                    <div class="item">
                        <div class="testimonials-one__single">
                            <div class="testimonials-one__qoute">
                                <img :src="require('../../assets/images/qoute-1-1.png')" alt="">
                            </div><!-- /.testimonials-one__qoute -->
                            <p class="testimonials-one__text">There are many variations of passages of lore ipsu available but
                                the majority.</p><!-- /.testimonials-one__text -->
                            <img :src="require('../../assets/images/team-1-1.jpeg')" alt="" class="testimonials-one__img">
                            <h3 class="testimonials-one__name">Anne Hall</h3><!-- /.testimonials-one__name -->
                            <p class="testimonials-one__designation">Student</p><!-- /.testimonials-one__designation -->
                        </div><!-- /.testimonials-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="testimonials-one__single">
                            <div class="testimonials-one__qoute">
                                <img :src="require('../../assets/images/qoute-1-1.png')" alt="">
                            </div><!-- /.testimonials-one__qoute -->
                            <p class="testimonials-one__text">There are many variations of passages of lore ipsu available but
                                the majority have suffered alteration in some form.</p><!-- /.testimonials-one__text -->
                            <img :src="require('../../assets/images/team-1-2.jpeg')" alt="" class="testimonials-one__img">
                            <h3 class="testimonials-one__name">Andre Obrien</h3><!-- /.testimonials-one__name -->
                            <p class="testimonials-one__designation">Student</p><!-- /.testimonials-one__designation -->
                        </div><!-- /.testimonials-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="testimonials-one__single">
                            <div class="testimonials-one__qoute">
                                <img :src="require('../../assets/images/qoute-1-1.png')" alt="">
                            </div><!-- /.testimonials-one__qoute -->
                            <p class="testimonials-one__text">There are many variations of passages of lore ipsu available but
                                the majority have suffered alteration in some form, by injected humour.</p>
                            <!-- /.testimonials-one__text -->
                            <img :src="require('../../assets/images/team-1-3.jpeg')" alt="" class="testimonials-one__img">
                            <h3 class="testimonials-one__name">Shane Vasquez</h3><!-- /.testimonials-one__name -->
                            <p class="testimonials-one__designation">Student</p><!-- /.testimonials-one__designation -->
                        </div><!-- /.testimonials-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="testimonials-one__single">
                            <div class="testimonials-one__qoute">
                                <img :src="require('../../assets/images/qoute-1-1.png')" alt="">
                            </div><!-- /.testimonials-one__qoute -->
                            <p class="testimonials-one__text">There are many variations of passages of lore ipsu available but
                                the majority.</p><!-- /.testimonials-one__text -->
                            <img :src="require('../../assets/images/team-1-4.jpeg')" alt="" class="testimonials-one__img">
                            <h3 class="testimonials-one__name">Maud Lee</h3><!-- /.testimonials-one__name -->
                            <p class="testimonials-one__designation">Student</p><!-- /.testimonials-one__designation -->
                        </div><!-- /.testimonials-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="testimonials-one__single">
                            <div class="testimonials-one__qoute">
                                <img :src="require('../../assets/images/qoute-1-1.png')" alt="">
                            </div><!-- /.testimonials-one__qoute -->
                            <p class="testimonials-one__text">There are many variations of passages of lore ipsu available but
                                the majority have suffered alteration in some form.</p><!-- /.testimonials-one__text -->
                            <img :src="require('../../assets/images/team-1-5.jpeg')" alt="" class="testimonials-one__img">
                            <h3 class="testimonials-one__name">Barbara Kennedy</h3><!-- /.testimonials-one__name -->
                            <p class="testimonials-one__designation">Student</p><!-- /.testimonials-one__designation -->
                        </div><!-- /.testimonials-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="testimonials-one__single">
                            <div class="testimonials-one__qoute">
                                <img :src="require('../../assets/images/qoute-1-1.png')" alt="">
                            </div><!-- /.testimonials-one__qoute -->
                            <p class="testimonials-one__text">There are many variations of passages of lore ipsu available but
                                the majority have suffered alteration in some form, by injected humour.</p>
                            <!-- /.testimonials-one__text -->
                            <img :src="require('../../assets/images/team-1-6.jpeg')" alt="" class="testimonials-one__img">
                            <h3 class="testimonials-one__name">Duane Carter</h3><!-- /.testimonials-one__name -->
                            <p class="testimonials-one__designation">Student</p><!-- /.testimonials-one__designation -->
                        </div><!-- /.testimonials-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="testimonials-one__single">
                            <div class="testimonials-one__qoute">
                                <img :src="require('../../assets/images/qoute-1-1.png')" alt="">
                            </div><!-- /.testimonials-one__qoute -->
                            <p class="testimonials-one__text">There are many variations of passages of lore ipsu available but
                                the majority.</p><!-- /.testimonials-one__text -->
                            <img :src="require('../../assets/images/team-1-1.jpeg')" alt="" class="testimonials-one__img">
                            <h3 class="testimonials-one__name">Sally Green</h3><!-- /.testimonials-one__name -->
                            <p class="testimonials-one__designation">Student</p><!-- /.testimonials-one__designation -->
                        </div><!-- /.testimonials-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="testimonials-one__single">
                            <div class="testimonials-one__qoute">
                                <img :src="require('../../assets/images/qoute-1-1.png')" alt="">
                            </div><!-- /.testimonials-one__qoute -->
                            <p class="testimonials-one__text">There are many variations of passages of lore ipsu available but
                                the majority have suffered alteration in some form.</p><!-- /.testimonials-one__text -->
                            <img :src="require('../../assets/images/team-1-2.jpeg')" alt="" class="testimonials-one__img">
                            <h3 class="testimonials-one__name">Iva Santos</h3><!-- /.testimonials-one__name -->
                            <p class="testimonials-one__designation">Student</p><!-- /.testimonials-one__designation -->
                        </div><!-- /.testimonials-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="testimonials-one__single">
                            <div class="testimonials-one__qoute">
                                <img :src="require('../../assets/images/qoute-1-1.png')" alt="">
                            </div><!-- /.testimonials-one__qoute -->
                            <p class="testimonials-one__text">There are many variations of passages of lore ipsu available but
                                the majority have suffered alteration in some form, by injected humour.</p>
                            <!-- /.testimonials-one__text -->
                            <img :src="require('../../assets/images/team-1-3.jpeg')" alt="" class="testimonials-one__img">
                            <h3 class="testimonials-one__name">Max Burns</h3><!-- /.testimonials-one__name -->
                            <p class="testimonials-one__designation">Student</p><!-- /.testimonials-one__designation -->
                        </div><!-- /.testimonials-one__single -->
                    </div><!-- /.item -->
                </div><!-- /.testimonials-one__carousel owl-carousel owl-theme -->
            </div><!-- /.container -->
        </section><!-- /.testimonials-one -->
        <section class="cta-one cta-one__home-one" style="background-image: url(assets/images/cta-bg-1-1.jpg);">
            <div class="container">
                <h2 class="cta-one__title">Zhyldyz academy one & only <br>
                    mission is to extend <br>
                    your knowledge base</h2><!-- /.cta-one__title -->
                <div class="cta-one__btn-block">
                    <a href="#" class="thm-btn cta-one__btn">Learn More</a><!-- /.thm-btn -->
                </div><!-- /.cta-one__btn-block -->
            </div><!-- /.container -->
        </section><!-- /.cta-one -->
        <Footer></Footer>

    </div><!-- /.page-wrapper -->

    </div>
</template>
<script>
import Footer from "../../components/footer/Footer.vue";
import '../../assets/css/style.css'
export default {
    name: "About",
    components: {
        Footer
    },
     methods :{
      goTo (url) {
         this.link = url;
         switch (url) {
            case 'Courses':
              this.$router.push({ name: 'Courses' });
               break;
            case 'Teachers':
               this.$router.push({ name: 'Teachers' });
               break;
            case 'News':
               this.$router.push({ name: 'News' });
               break;
            case 'About':
               this.$router.push({ name: 'About' });
               break;
            case 'Home':
               this.$router.push({ name: 'Main' });
               break;
         }
      },
    }
}
</script>